import Axios from '../services/axios';

const PATH = 'lender';

export function listBank() {
  return Axios.get(`${PATH}/banks/list`);
}

export function allListBank(limit = 100) {
  return Axios.get(`${PATH}/banks/list/active?limit=${limit}`);
}

export function createLinkAccount(data: any, gateway: string) {
  return Axios.post(
    `${PATH}/bank-connector/link/create?gateway=${gateway}`,
    data,
  );
}

export function getLinkAccountResult(transaction: string, gateway: string) {
  return Axios.get(
    `${PATH}/bank-connector/link/result/${transaction}?gateway=${gateway}`,
  );
}

export function createTransfer(data: any, gateway: string) {
  return Axios.post(
    `${PATH}/bank-connector/transfer/create?gateway=${gateway}`,
    data,
  );
}

export function getTransferResult(transaction: string, gateway: string) {
  return Axios.get(
    `${PATH}/bank-connector/transfer/result/${transaction}?gateway=${gateway}`,
  );
}
