
import { defineComponent, ref } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import IconChevronRight from '@/components/Icon/IconChevronRight.vue';
import SketetonLoader from '@/components/Base/BaseSkeletonLoader.vue';
import constRouter from '@/constants/constRouter';
import { useStore, mapGetters, mapMutations } from 'vuex';
import { listBankAccount } from '@/apis/ApiCustomer';
import { getLinkAccountResult } from '@/apis/ApiBank';
import { detailContract } from '@/apis/ApiContract';
import BaseModal from '@/components/Base/BaseModal.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { formatAccountBankNumber } from '@/utils/index';
export default defineComponent({
  components: {
    DefaultLayout,
    IconChevronRight,
    SketetonLoader,
    BaseModal,
    BaseButton,
  },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const isGetResult = ref(false);
    const openModal = ref(false);
    const dataModal = ref({});
    const fetchBankAccountList = () => {
      loading.value = true;
      listBankAccount()
        .then((res) => {
          store.commit('bank/setBankAccountList', res.data);
          loading.value = false;
        })
        .catch((err) => {
          console.log(err);
          loading.value = false;
        });
    };
    fetchBankAccountList();
    return {
      dataModal,
      loading,
      openModal,
      isGetResult,
      fetchBankAccountList,
      formatAccountBankNumber,
    };
  },
  computed: {
    ...mapGetters({
      data: 'bank/getBankAccountList',
    }),
  },
  mounted() {
    const params: any = this.$route.query.params;
    if (params) {
      const obj = JSON.parse(atob(params));
      const pathname = window.location.pathname;
      window.history.pushState({}, document.title, pathname);
      this.getResultLinkAccount(obj.transactionId, obj.gateway);
      const contractId: any = localStorage.getItem('contractId');
      this.getDetailContract(contractId);
    }
  },
  methods: {
    ...mapMutations({
      setBankTransfer: 'setBankTransfer',
      setTransferInfo: 'setTransferInfo',
    }),
    closeModal() {
      this.openModal = false;
    },
    handleChooseAccount(bankInfo: any) {
      this.setBankTransfer(bankInfo);
      this.$router.push({
        name: constRouter.INVESTMENT_CONFIRM.name,
      });
    },
    handleAddAccount() {
      this.$router.push({
        name: constRouter.BANK_LIST.name,
      });
    },
    async getResultLinkAccount(transactionId: string, binCode: string) {
      this.isGetResult = true;
      const res = await getLinkAccountResult(transactionId, binCode);
      if (res.data.statusId === 3) {
        setTimeout(() => {
          this.getResultLinkAccount(transactionId, binCode);
        }, 3000);
      }
      if (res.data.statusId === 4 || res.data.statusId === 5) {
        this.fetchBankAccountList();
        this.dataModal = res.data;
        this.openModal = true;
        this.isGetResult = false;
        return;
      }
    },
    async getDetailContract(contractId: string) {
      const res = await detailContract(contractId);
      this.setTransferInfo(res.data);
    },
  },
});
